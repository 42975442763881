"use client";

// LocationContext.tsx
import React, { createContext, useState, useContext } from 'react';
interface LocationObj {
  location: string;
  updateMap?: boolean;
}
interface LocationContextProps {
  location: LocationObj;
  setLocation: (location: LocationObj) => void;
}
const LocationContext = createContext<LocationContextProps | undefined>(undefined);
export const LocationProvider: React.FC<{
  children?: React.ReactNode;
}> = ({
  children
}) => {
  const [location, setLocation] = useState({
    location: ''
  });
  return <LocationContext.Provider value={{
    location,
    setLocation
  }} data-sentry-element="unknown" data-sentry-component="LocationProvider" data-sentry-source-file="Context.tsx">
      {children}
    </LocationContext.Provider>;
};
export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};