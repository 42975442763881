import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import ClickOutside from "@/components/ClickOutside";
const DropdownDownload = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(false);
  return <ClickOutside onClick={() => setDropdownOpen(false)} className="relative" data-sentry-element="ClickOutside" data-sentry-component="DropdownDownload" data-sentry-source-file="DropdownDownload.tsx">
      <li className="relative">
        <Link onClick={() => {
        setNotifying(false);
        setDropdownOpen(!dropdownOpen);
      }} className="relative flex h-8.5 w-8.5 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white" href="/download" data-sentry-element="Link" data-sentry-source-file="DropdownDownload.tsx">
          <span className={`absolute -right-0.5 -top-0.5 z-1 h-2 w-2 rounded-full bg-meta-1 ${notifying === false ? "hidden" : "inline"}`}>
            <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
          </span>
          <svg className="fill-current duration-300 ease-in-out" width="25" height="25" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="DropdownDownload.tsx">
            <path d="M55.666 43.813C55.1356 43.813 54.6268 44.0237 54.2518 44.3988C53.8767 44.7738 53.666 45.2826 53.666 45.813V47.313C53.666 47.976 53.4026 48.6119 52.9338 49.0807C52.4649 49.5496 51.829 49.813 51.166 49.813H12.166C11.5029 49.813 10.8671 49.5496 10.3982 49.0807C9.92938 48.6119 9.66599 47.976 9.66599 47.313V45.813C9.66599 45.2826 9.45527 44.7738 9.0802 44.3988C8.70513 44.0237 8.19642 43.813 7.66599 43.813C7.13555 43.813 6.62684 44.0237 6.25177 44.3988C5.8767 44.7738 5.66599 45.2826 5.66599 45.813V47.313C5.6681 49.0362 6.35362 50.6883 7.57214 51.9068C8.79067 53.1254 10.4427 53.8109 12.166 53.813H51.166C52.8892 53.8109 54.5413 53.1254 55.7599 51.9068C56.9784 50.6883 57.6639 49.0362 57.666 47.313V45.813C57.666 45.2826 57.4553 44.7738 57.0802 44.3988C56.7051 44.0237 56.1964 43.813 55.666 43.813Z" fill="" data-sentry-element="path" data-sentry-source-file="DropdownDownload.tsx" />
            <path d="M21.558 35.477L30.549 41.47L30.556 41.475H30.568C30.7126 41.5674 30.8685 41.6406 31.032 41.693C31.072 41.707 31.11 41.719 31.15 41.73C31.3176 41.7778 31.4907 41.8033 31.665 41.806C31.8396 41.8034 32.0131 41.7778 32.181 41.73C32.221 41.719 32.259 41.707 32.299 41.693C32.4622 41.6408 32.6178 41.5676 32.762 41.475H32.775L32.782 41.47L41.771 35.477C41.9895 35.3312 42.1772 35.1439 42.3233 34.9256C42.4695 34.7073 42.5712 34.4623 42.6226 34.2048C42.674 33.9472 42.6742 33.6819 42.6232 33.4243C42.5721 33.1666 42.4708 32.9215 42.325 32.703C42.0307 32.2619 41.5732 31.9558 41.0532 31.8519C40.5332 31.748 39.9932 31.8549 39.552 32.149L33.662 36.076V12.813C33.662 12.2826 33.4513 11.7738 33.0762 11.3988C32.7011 11.0237 32.1924 10.813 31.662 10.813C31.1315 10.813 30.6228 11.0237 30.2478 11.3988C29.8727 11.7738 29.662 12.2826 29.662 12.813V36.076L23.771 32.149C23.5525 32.0032 23.3074 31.9019 23.0497 31.8508C22.792 31.7997 22.5268 31.7999 22.2692 31.8514C22.0116 31.9028 21.7667 32.0045 21.5484 32.1506C21.3301 32.2968 21.1427 32.4844 20.997 32.703C20.8515 32.9219 20.7506 33.1674 20.7001 33.4253C20.6496 33.6833 20.6504 33.9487 20.7025 34.2063C20.7546 34.4639 20.8569 34.7087 21.0037 34.9268C21.1505 35.1448 21.3389 35.3318 21.558 35.477Z" fill="" data-sentry-element="path" data-sentry-source-file="DropdownDownload.tsx" />
          </svg>
        </Link>

      </li>
    </ClickOutside>;
};
export default DropdownDownload;