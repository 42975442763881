import React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
const SidebarDropdown = ({
  item,
  selected
}: any) => {
  const pathname = usePathname();
  console.log({
    pathname,
    item,
    selected
  });
  return <>
      <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
        {item.map((item: any, index: number) => <li key={index}>
            <Link href={item.route} className={`group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ${selected === item.label ? "text-white" : ""}`}>
              {item.label}
            </Link>
          </li>)}
      </ul>
    </>;
};
export default SidebarDropdown;