import React, { useState, useEffect } from 'react';
interface RotatingWordsProps {
  words: string[];
  interval?: number; // Optional interval in milliseconds
  customStyle?: React.CSSProperties;
}
const RotatingWords: React.FC<RotatingWordsProps> = ({
  words,
  interval = 3500,
  customStyle
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  useEffect(() => {
    const wordRotation = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % words.length);
        setFade(true);
      }, 500); // Time to wait before changing the word (for fade-out)
    }, interval);
    return () => clearInterval(wordRotation); // Clean up interval on component unmount
  }, [words, interval]);
  return <span style={{
    ...customStyle
  }} className={`block text-xs transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`} data-sentry-component="RotatingWords" data-sentry-source-file="index.tsx">
      {words[currentIndex]}
    </span>;
};
export default RotatingWords;