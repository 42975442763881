"use client";

import React, { useState, ReactNode } from "react";
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
export default function DefaultLayout({
  children,
  noPadding = false
}: {
  noPadding?: boolean;
  children: React.ReactNode;
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return <>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex" style={{
      height: "100%"
    }}>
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} data-sentry-element="Sidebar" data-sentry-source-file="DefaultLayout.tsx" />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col lg:ml-72.5">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} data-sentry-element="Header" data-sentry-source-file="DefaultLayout.tsx" />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="h-full">
            <div className={"mx-auto h-full" + (noPadding ? "" : "p-4 md:p-6 2xl:p-10")}>
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </>;
}